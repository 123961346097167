import React from "react";
import {
  SEO,
  PageLayout,
  HeroElement,
  MenuFilesDisplay,
} from "@bluefin/components";
import { Grid } from "semantic-ui-react";
import { graphql } from "gatsby";

import WebsiteLayout from "../components/WebsiteLayout.jsx";
import { getPageHeroImage } from "../utils/utils";

export default class OurStoryPage extends React.PureComponent {
  render() {
    const { data } = this.props;
    const { fishermanBusiness, fishermanBusinessWebsitePage } = data;

    return (
      <WebsiteLayout>
        <SEO
          businessName={fishermanBusiness.name}
          pageName={fishermanBusinessWebsitePage.title}
          images={getPageHeroImage({
            options: fishermanBusinessWebsitePage.components,
          })}
        />
        <PageLayout
          hero={
            <div className={"hero"}>
              <h1>Our History</h1>
              <HeroElement
                header={""}
                tagline={""}
                images={getPageHeroImage({
                  options: fishermanBusinessWebsitePage.components,
                })}
                ctaInverted={true}
                ctaColor={"white"}
                containerColor={"primary"}
                textColor={"white"}
                showMultiple={true}
                overlay={true}
                fullWidth={true}
                parallax={false}
                containerAs={"none"}
                height={500}
              />
            </div>
          }
        >
          <Grid
            stackable={true}
            className={"component-section-container"}
            verticalAlign={"middle"}
            textAlign={"left"}
          >
            <Grid.Column width={16}>
              <MenuFilesDisplay
                files={[
                  {
                    url: "https://fisherman.gumlet.io/public/texashotdogs/Texas_About_J5n1PWb.pdf",
                    filename: "Our History",
                  },
                ]}
              />
            </Grid.Column>
          </Grid>
        </PageLayout>
      </WebsiteLayout>
    );
  }
}

export const query = graphql`
  query {
    fishermanBusiness {
      name
    }
    fishermanBusinessWebsitePage(title: { eq: "Our History" }) {
      title
      components {
        fastId
        order
        files {
          file
          altText
          gatsbyFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    }
  }
`;
